@tailwind base;
@tailwind components;
@tailwind utilities;

.gallery-img {
    clip-path: inset(0%);
    transition: clip-path 0.5s;
}
  
.gallery-img:hover {
    clip-path: inset(10%);
    cursor: pointer;
}